@charset "UTF-8";

/*================================================
Start Default CSS
=================================================*/

@font-face {
  font-family: 'North-Star';
  src: url('../fonts/northstar.ttf') format('truetype');
}
@font-face {
  font-family: 'SourceSansPro-Regular';
  src: url('../fonts/SourceSansPro-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'ebrima-bold';
  src: url('../fonts/ebrima-bold.ttf') format('truetype');
}

:root {
  --fontFamily: 'ebrima-bold', sans-serif;
  --fontFamily2: 'SourceSansPro-Regular', sans-serif;
  --whiteColor: #ffffff;
  --blackColor: #000000;
  --pinkColor: #DC70FA;
  --blueColor: #3772FF;
  --neonGreenColor: #39FDDB;
  --fontSize: 16px;
  --transition: .5s;
}

body {
  margin: 0;
  padding: 0;
}

.master-container {
  display: flex;
  flex-direction: column;
  min-height: 90vh;
  justify-content: center;
}

.wrapper {
  background: url('/public/images/background.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

/*================================================
End Default CSS
=================================================*/


/*================================================
Start Language Selector CSS
=================================================*/

.mantine-Select-input {
  background: var(--blackColor);
  color: var(--whiteColor);
  text-align: center;
  font-size: 1em;
  border-radius: 0px;
  padding-left: 10px;
  padding-right: 20px;
}

/*================================================
End Language Selector CSS
=================================================*/

/*================================================
  Go Top CSS
=================================================*/
.go-top {
  z-index: 4;
  opacity: 0;
  right: 20px;
  width: 45px;
  height: 45px;
  bottom: 20px;
  position: fixed;
  cursor: pointer;
  font-size: 30px;
  border-radius: 50%;
  visibility: hidden;
  text-align: center;
  color: var(--blackColor);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  background-color: var(--whiteColor);
}

.go-top i {
  left: 0;
  top: 50%;
  right: 0;
  text-align: center;
  position: absolute;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  margin-left: auto;
  margin-right: auto;
}

.go-top.active {
  opacity: 1;
  bottom: 20px;
  visibility: visible;
}

.go-top:hover {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
}

/*================================================
  Toast CSS
=================================================*/

.reapop__notification-title {
  font-size: 16px !important;
}

.reapop__notification-message {
  font-size: 16px !important;
}

/*================================================
Navbar CSS
=================================================*/
.m-navbar .nextui-navbar-container {
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: transparent;
}

.nav-burger .mantine-Burger-burger,
.mantine-Burger-burger::before,
.mantine-Burger-burger::after {
  height: calc(3.5rem / 12);
  width: 2rem;
}

@media only screen and (max-width: 1250px) {
  .content-flex {
    flex-direction: column;
    align-items: center;
  }
  .content-flex :nth-child(1) {
    order: 2;
  }
  .content-flex :nth-child(2) {
    order: 1;
  }
  .content-flex :nth-child(3) {
    order: 3;
  }
}

@media only screen and (max-width: 530px) {
  .second-content-flex {
    flex-direction: column;
    gap: 10px;
    margin-top: 1rem;
  }

  .coming-soon-text {
    text-align: center;
  }

  .footer-flex {
    justify-content: center;
  }

  .lottie-player {
    padding-top: 6rem;
  }
}

/*================================================
  unbox trust mansory CSS
=================================================*/
.ut-mansory-container {  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(5, 1fr);
  gap: 60px 40px;
  grid-auto-flow: row;
  grid-template-areas:
    "ut-1 ."
    "ut-1 ut-2"
    "ut-3 ut-2"
    "ut-3 ut-4"
    ". ut-4";
}

.ut-1 {  display: grid;
  grid-auto-columns: 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-area: ut-1;
  max-width: 402px;
}

.ut-2 {  display: grid;
  grid-auto-columns: 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-area: ut-2;
  max-width: 402px;
}

.ut-3 {  display: grid;
  grid-auto-columns: 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-area: ut-3;
  max-width: 402px;
}

.ut-4 {  display: grid;
  grid-auto-columns: 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-area: ut-4;
  max-width: 402px;
}

@media screen and (max-width: 600px) {
  .ut-mansory-container {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      "ut-1"
      "ut-2"
      "ut-3"
      "ut-4";
    gap: 20px;
  }
}

.section-7-roadmap-list li {
  margin-bottom: 0;
  line-height: 1;
}
.section-7-roadmap-list li::marker {
  color: var(--whiteColor);
}
.section-7-roadmap-nested-list li::marker {
  color: var(--neonGreenColor);
}